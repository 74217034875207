<template>
  <div class="home">
    <Section light>
      <h1>Science</h1>
      <v-col cols="12">
        <p>
          Astrobiology, the study of extraterrestrial life, is a vast field. It
          covers disciplines such as chemistry, optics, geology, astrometry,
          physics, molecular biology, bio-e, chem-e, seismology, environmental,
          microbiology, evolutionary bio, and more.
          <br /><br />
          The University Rover Competition focuses specifically on NASA's Mars
          rover missions. Since the first Mars landers, searching for signs of
          life has been central to the missions, and life testing capability has
          drastically expanded.
        </p>
        <v-img
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/science/generic1.jpg')"
        />
      </v-col>
      <v-col cols="12">
        <p>
          The subteam is responsible for researching and building functional
          tests for extraterrestrial life. We survey astrobiology literature,
          develop experiment protocols, and design instruments. Science works
          closely with the other subteams to integrate our science module onto
          the Mars rover. At the competition, we test several samples and
          classify them as extinct, extant, or no life. We also analyze rock
          formations for evidence of water. Lastly, the rover must be able to
          cache and deliver a sample of interest to the judges.
        </p>
        <v-img
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/science/carosel.jpg')"
        />
        <h2>Assays: Fluorometry</h2>
        <p>
          Last year we ran two assay tests. A DAPI stain to detect DNA and a
          Nile Red stain to detect lipids. Both tests involved extensive lab
          work to fine-tune the procedure and determine its sensitivity. We also
          built our own spectrometer to detect the fluorescent signals from the
          molecules.
        </p>
        <br />
        <h2>Optics: Raman</h2>
        <p>
          The rover also included a Raman spectrometer to detect beta carotene
          and kerogens in the soil samples. We built our own Raman spectrometer
          and used known Raman spectrums to calibrate and ensure it worked. We
          worked closely with the electrical team so that the results could be
          transmitted from the remote testing site to the astronaut booth.
        </p>
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <p text-align="center">Collecting a soil sample.</p>
        <v-img
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/science/collection.jpg')"
        />
      </v-col>
      <v-col class="px-4 col-md-6 col-12">
        <p text-align="center">Loading a sample into the collection funnel.</p>
        <v-img
          class="shrink mx-auto"
          contain
          :max-width="450"
          :src="require('@/assets/science/funnel.jpg')"
        />
      </v-col>
      <v-col cols="12">
        <p>
          Being part of Yonder Dynamics science team offers valuable skills even
          outside the field of astrobiology. Our members gain experience in
          independent research, scientific writing, and designing and running
          their own lab experiments. There are also plenty of opportunities to
          pick up coding, bioinformatics, and mechanical skills.
        </p>
      </v-col>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Mechanical',
  components: {
    Section,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}

p1 {
  text-align: center;
}
</style>
